import { render, staticRenderFns } from "./collapse.component.vue?vue&type=template&id=eeb5ad3a&"
import script from "./collapse.component.ts?vue&type=script&lang=ts&"
export * from "./collapse.component.ts?vue&type=script&lang=ts&"
import style0 from "./collapse.component.sass?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "collapse.component.vue"
export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpansionPanel } from 'vuetify/lib'
installComponents(component, {
  VExpansionPanel,
})
