import Vue from "vue";
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class TituloComponent extends Vue
{
    @Prop({ type: Number, default: 1 })
    private relevancia!: 1 | 2 | 3 | 4 | 5 | 6;

    @Prop({ type: String, default: "Lorem ipsum dolor sit amet" })
    private titulo!: string;

    @Prop({ type: String, default: "" })
    private cor!: string;

    @Prop({ type: Number, default: 36 })
    private fontDesk!: number;

    @Prop({ type: Number, default: 18 })
    private fontMobile!: number;

    @Prop({ type: Number, default: 1920 })
    private readonly telaDesk!: number;

    @Prop({ type: Number, default: 320 })
    private readonly telaMobile!: number;

    @Prop({ type: String, default: "left" })
    private alinhamento!: "left" | "center" | "right";

    private tamanhoFont: string = `${this.fontDesk}px`;
    private larguraTela: number = 0;

    constructor()
    {
        super();
    }

    created()
    {
        this.pegarLarguraTela();
    }

    private pegarLarguraTela():void
    {
        window.addEventListener("resize", ( event: any )=>
        {
            this.larguraTela = event.target.innerWidth;
            this.fontScale();
        });
    }

    private fontScale():void
    {
        if ( this.larguraTela == this.fontDesk )
        {
            this.tamanhoFont = `${this.fontDesk}px  `;
        }
        else if ( this.larguraTela == this.fontMobile )
        {
            this.tamanhoFont = `${this.fontMobile}px`;
        }
        else
        {
            this.tamanhoFont = `calc( ${this.fontMobile}px + (${this.fontDesk} - ${this.fontMobile}) * ( (100vw - ${this.telaMobile}px) / ( ${this.telaDesk} - ${this.telaMobile})) )`;
        }
    }
}
