import "@babel/polyfill";
import "intersection-observer";

import Vue from "vue";
import store from "@/store";

import "@/plugins/vue-apollo";
import { apolloProvider } from "@/apollo/vue-apollo";
// import "@/interceptor/django-interceptor";

import "@/plugins/vuetify";
import "@/plugins/veevalidate";
import "@/plugins/awesome-swiper";
import "@/plugins/vue-observe-visibility";

import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import "@/components";

Vue.config.productionTip = false;

var App = new Vue({
    store,
    apolloProvider
}).$mount('#app')
.$nextTick()
.finally(()=>
{
    const loadingGeral = <HTMLElement>document.querySelector("#loading-geral");
    const appVue = <HTMLElement>document.querySelector("#app");
    const animateContent = <HTMLElement>document.querySelector(".animate-content");
    const animatefooter = <HTMLElement>document.querySelector(".animate-footer");

    appVue.style.display = "block";
    setTimeout(()=> {
        loadingGeral.outerHTML = "";
        appVue.style.opacity = "1";
    },300);

    setTimeout(()=>{
        if( animateContent ) {
            animateContent.style.display = "block";
        }
    },900);
    setTimeout(()=>{
        if( animatefooter ) {
            animatefooter.style.display = "block";
        }
    },1200);
    //FIM deixar primeiro carregamento mais fluido
});
