import Vue from "vue";
import {Component, Prop, Watch} from "vue-property-decorator";

import { FontesModel } from "./lista-fontes.component.model";
import { QueryResult } from "vue-apollo/types/vue-apollo";


@Component
export default class ListaFontesComponent extends Vue
{

    $apollo: any;

    @Prop({ type: String, default: "" })
    protected titulo!: string;

    @Prop({ type: Number, default: 6 })
    protected limiteItens!: number;

    protected loading: boolean = false;
    protected limite: number = this.limiteItens;
    protected categoria: string = "";
    protected fontes: Array<any> = [] ;
    protected ultimoItem: String = "";
    protected proximaPagina: boolean = false;

    protected diretorias: Array<any> = [
        {
            "nome": "Todas as diretorias",
            "slug": ""
        }
    ];
    protected Diretorias: String = "";

    protected instituicao:any = [
        {
            nome:"Todas as instituições",
            value:""
        },
        {
            nome:"CNI",
            value:"CNI"
        },
        {
            nome:"SESI",
            value:"SESI"
        },
        {
            nome:"SENAI",
            value:"SENAI"
        },
        {
            nome:"IEL",
            value:"IEL"
        },
    ];
    protected Instituicao: String = "";

    constructor()
    {
        super();
    }

    protected filtrar():void {

        this.$apollo
            .query({
                query: FontesModel.filter.query,
                variables: {
                    limit: this.limite,
                    instituicao: this.Instituicao,
                    diretoria: this.Diretorias
                }
            })
            .then(( results: QueryResult<any> )=>
            {
                this.loading = false;
                this.fontes = results.data.fontes.edges;
                this.proximaPagina = results.data.fontes.pageInfo.hasNextPage;
            })
            .catch(( error: any )=>
            {
                this.loading = false;
                console.error("FiltrarComponent queryConteudos error", error);
            });
    }

    mounted()
    {
        this.queryConteudos();
        this.queryDiretorias();
    }

    protected carregarMais():void {
        this.limite += this.limiteItens
    }

    @Watch("limite")
    protected queryConteudos():void
    {
        this.loading =  true;

        this.$apollo
            .query({
                query: FontesModel.filter.query,
                variables: {
                    limit: this.limite,
                    ultimoItem: this.ultimoItem
                }
            })
            .then(( results: QueryResult<any> )=>
            {

                this.loading = false;
                this.fontes = this.fontes.concat(results.data.fontes.edges);
                this.ultimoItem = results.data.fontes.pageInfo.endCursor;
                this.proximaPagina = results.data.fontes.pageInfo.hasNextPage;

            })
            .catch(( error: any )=>
            {
                this.loading = false;
                console.error("FontesComponent queryConteudos error", error);
            });
    }

    protected queryDiretorias():void
    {
        this.$apollo
            .query({
                query: FontesModel.diretorias.query
            })
            .then(( results: QueryResult<any> )=>
            {
                this.diretorias = this.diretorias.concat(results.data.diretorias.map(function(e) { return {'nome': e.diretoria, 'slug': e.diretoria }}));
                // console.log(this.diretorias)
            })
            .catch(( error: any )=>
            {
                console.error("Instituicoes query error", error);
            });
    }

}