import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";

@Component
export default class AovivoComponent extends Vue
{

    @Prop({ type: String, default: "" })
    protected fbTitulo!: string;

    @Prop({ type: String, default: "" })
    protected fbLink!: string;

    @Prop({ type: Boolean, default: false })
    protected fbAtivo!: boolean;

    @Prop({ type: String, default: "" })
    protected twTitulo!: string;

    @Prop({ type: String, default: "" })
    protected twLink!: string;

    @Prop({ type: Boolean, default: false })
    protected twAtivo!: boolean;

    protected animating: string = "";

    constructor()
    {
        super();
    }


    protected mounted(): void {

        setTimeout(()=>{

            this.animating = "animating";

        },300)

    }
}