import gql from "graphql-tag";

export const FontesModel = {
    all: {
        query: gql`
            query Fontes {
                fontes {
                edges {
                  node {
                    nome
                    slug
                    casas
                    cargo
                    diretoria
                  }
                }
              }
            }
        `,
        loadingKey: "loading",
        update: (data:any) => {
            let fontes = data.contents;
            return fontes;
        }
    },
    filter: {

        query: gql`
            query Fontes( $limit: Int!, $ultimoItem: String, $instituicao: String, $diretoria: String  ) {
                fontes( first: $limit, after: $ultimoItem, casas_Nome_Iexact: $instituicao, diretoria_Iexact: $diretoria) {
                    edges {
                        node {
                        nome
                        slug
                        casas
                        cargo
                        thumbnail
                        slug
                        diretoria
                    }
                },pageInfo {
                    endCursor
                    hasNextPage
                }
              }
            }
        `,
        loadingKey: "loading",
        update: (data:any) => {
            let fontes = data.contents;
            return fontes;
        }

    },
    diretorias: {

        query: gql`
            query Diretorias {
                diretorias {
                    diretoria
                }
            }
        `,
        loadingKey: "loading",
        update: (data:any) => {
            let diretorias = data.contents;
            return diretorias;
        }

    }

};
