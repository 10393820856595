import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
//

@Component
export default class AbaContentComponent extends Vue
{
    @Prop({ type: String, default: "" })
    protected id!: string;

    constructor()
    {
        super();
    }
}