import { render, staticRenderFns } from "./imagem.component.vue?vue&type=template&id=3f997337&"
import script from "./imagem.component.ts?vue&type=script&lang=ts&"
export * from "./imagem.component.ts?vue&type=script&lang=ts&"
import style0 from "./imagem.component.sass?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "imagem.component.vue"
export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VDialog } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VImg } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VProgressCircular } from 'vuetify/lib'
installComponents(component, {
  VBtn,
  VContainer,
  VDialog,
  VIcon,
  VImg,
  VLayout,
  VProgressCircular,
})
