import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";

@Component
export default class DownloadComponent extends Vue
{
    @Prop({ type: String, default: "" })
    public downloadUrl!: string;

    @Prop({ type: String, default: "Clique aqui para baixar" })
    public downloadText!: string;

    constructor()
    {
        super();
    }

}
