import gql from "graphql-tag";

export let ContatoModel: any = {
    register: gql`
        mutation Contact($nome: String!, $email: String!, $instituicao: String!, $mensagem: String!) {
            createContact(contactData: {name: $nome, email: $email, instituicao: $instituicao, comment: $mensagem}) {
                contact {
                    name
                    email
                    instituicao
                    comment
                    __typename
                }
                status
                __typename
            }
        }
    `
};
