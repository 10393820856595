import gql from "graphql-tag";

export let BannersModel: any = {
    all: {
        query: gql`
                query Banners {
                    banners {
                        page,
                        totalPages,
                        hasNext,
                        hasPrev,
                        objects {
                            id,
                            title,
                            subtitle,
                            description,
                            image,
                            link
                        }
                    }
                }
            `,
        loadingKey: "loading",
        update: ( data:any )=>
        {
            let banners = data.banners;
            return banners;
        }
    },
    filter: {
        query: gql`
                query FilterBanners( $categoria: [String]!, $id: String! ) {
                    banners( filterFields: $categoria, filterText: $id ) {
                        page,
                        totalPages,
                        hasNext,
                        hasPrev,
                        objects {
                            id,
                            title,
                            subtitle,
                            description,
                            image,
                            link
                        }
                    }
                }
            `,
        variables: {
            categoria: "agrupador",
            id: "0"
        },
        loadingKey: "loading",
        update: ( data:any )=>
        {
            let banners = data.banners;
            return banners;
        }
    }
};
