import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class BotaoComponent extends Vue
{
    @Prop({ type: String, default: "Botão" })
    protected texto!: string;

    @Prop({ type: Boolean, default: false })
    protected flat!: boolean;

    @Prop({ type: Boolean, default: false })
    protected depressed!: boolean;

    @Prop({ type: Boolean, default: false })
    protected outline!: boolean;

    @Prop({ type: Boolean, default: false })
    protected round!: boolean;

    @Prop({ type: String, default: "medium" })
    protected tamanho!: "small" | "medium" | "large";

    @Prop({ type: String, default: "left" })
    protected alinhamento!: "left" | "center" | "right";

    @Prop({ type: String, default: "#ffffff" })
    protected corBotao!: string;

    @Prop({ type: String, default: "#333333" })
    protected corTexto!: string;

    @Prop({ type: String, default: "" })
    protected link!: string;

    constructor()
    {
        super();
    }
}
