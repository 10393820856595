import gql from "graphql-tag";

export let NewsletterModel: any = {
    register: gql`
        mutation Newsletter( $nome: String!, $email: String! )
        {
            createNewsletter (
                newsletterData: {
                    name: $nome
                    email: $email
                }
            )
            {
                newsletter
                {
                    name
                    email
                }
                status
                message
            }
        }
    `
};
