import Vue from "vue";

import AppComponent from "@/app.component";
Vue.component("AppComponent", AppComponent);

import HeaderCniComponent from "@/components/header-cni/header-cni.component";
Vue.component("HeaderCniComponent", HeaderCniComponent);

import HeaderComponent from "@/components/header/header.component";
Vue.component("HeaderComponent", HeaderComponent);

import FooterComponent from "@/components/footer/footer.component";
Vue.component("FooterComponent", FooterComponent);

import SlidershowComponent from "@/components/slidershow/slidershow.component";
Vue.component("SlidershowComponent", SlidershowComponent);

import NewsletterComponent from "@/components/newsletter/newsletter.component";
Vue.component("NewsletterComponent", NewsletterComponent);

import ContatoComponent from "@/components/contato/contato.component";
Vue.component("ContatoComponent", ContatoComponent);

import TituloComponent from "@/components/titulo/titulo.component";
Vue.component("TituloComponent", TituloComponent);

import LinkComponent from "@/components/link/link.component";
Vue.component("LinkComponent", LinkComponent);

import ImagemComponent from "@/components/imagem/imagem.component";
Vue.component("ImagemComponent", ImagemComponent);

import VideoComponent from "@/components/video/video.component";
Vue.component("VideoComponent", VideoComponent);

import BotaoComponent from "@/components/botao/botao.component";
Vue.component("BotaoComponent", BotaoComponent);

import AbasComponent from "@/components/abas/abas.component";
Vue.component("AbasComponent", AbasComponent);

import AbaComponent from "@/components/aba/aba.component";
Vue.component("AbaComponent", AbaComponent);

import AbaContentComponent from "@/components/aba-content/aba-content.component";
Vue.component("AbaContentComponent", AbaContentComponent);

import ImagemParallaxComponent from "@/components/imagem-parallax/imagem-parallax.component";
Vue.component("ImagemParallaxComponent", ImagemParallaxComponent);

import LocalizacaoComponent from "@/components/localizacao/localizacao.component";
Vue.component("LocalizacaoComponent", LocalizacaoComponent);

import GaleriaComponent from "@/components/galeria/galeria.component";
Vue.component("GaleriaComponent", GaleriaComponent);

import GaleriaSlideComponent from "@/components/galeria-slide/galeria-slide.component";
Vue.component("GaleriaSlideComponent", GaleriaSlideComponent);

import TextoRicoComponent from "@/components/texto-rico/texto-rico.component";
Vue.component("TextoRicoComponent", TextoRicoComponent);

import AgenciaNoticiasComponent from "@/components/agencia-noticias/agencia-noticias.component";
Vue.component("AgenciaNoticiasComponent", AgenciaNoticiasComponent);

import DownloadComponent from "@/components/download/download.component";
Vue.component("DownloadComponent", DownloadComponent);

import AovivoComponent from "@/components/aovivo/aovivo.component";
Vue.component("AovivoComponent", AovivoComponent);

import EspacadorComponent from "@/components/espacador/espacador.component";
Vue.component("EspacadorComponent", EspacadorComponent);

import MidiasSociaisComponent from "@/components/midias-sociais/midias-sociais.component";
Vue.component("MidiasSociaisComponent", MidiasSociaisComponent);

import AtendimentoAImprensaComponent from "@/components/atendimento-a-imprensa/atendimento-a-imprensa.component"
Vue.component("AtendimentoAImprensaComponent", AtendimentoAImprensaComponent);

import CollapseContentComponent from "@/components/collapse-content/collapse-content.component";
Vue.component("CollapseContentComponent", CollapseContentComponent);

import CollapseComponent from "@/components/collapse/collapse.component";
Vue.component("CollapseComponent", CollapseComponent);

import ListaComponent from "@/components/lista/lista.component";
Vue.component("ListaComponent", ListaComponent);

import ListaFiltroComponent from "@/components/lista-filtro/lista-filtro.component";
Vue.component("ListaFiltroComponent", ListaFiltroComponent);

import ListaFontesComponent from "@/components/lista-fontes/lista-fontes.component";
Vue.component("ListaFontesComponent", ListaFontesComponent);

import FonteComponent from "@/components/fonte/fonte.component";
Vue.component("FonteComponent", FonteComponent);

import ListaLinksComponent from "@/components/lista-links/lista-links.component";
Vue.component("ListaLinksComponent", ListaLinksComponent);