import VueApollo from "vue-apollo";
import fetch from 'unfetch';

import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';

import store from '@/store';

store.dispatch("setEnvironment");

// HTTP connexion to the API
const httpLink = new HttpLink({
    uri: store.state.graphql,
    fetch: fetch
});

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
const apolloClient = new ApolloClient({
    link: httpLink,
    cache,
});

// Export provider
export const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
});
