import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";

@Component

export default class FonteComponent extends Vue
{

    @Prop({ type: String, default: "" })
    protected titulo!: string;

    @Prop({ type: String, default: "" })
    protected imagem!: string;

    @Prop({ type: String, default: "" })
    protected nome!: string;

    @Prop({ type: String, default: "" })
    protected cargo!: string;

    @Prop({ type: String, default: "" })
    protected diretoria!: string;

    @Prop({ type: String, default: "" })
    protected casas!: string;

    @Prop({ type: String, default: "" })
    protected linkedin!: string;

    @Prop({ type: String, default: "" })
    protected curriculo!: string;

    constructor()
    {
        super();
    }
}