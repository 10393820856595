import { Vue, Component } from "vue-property-decorator";
import HeaderCniComponent from "@/components/header-cni/header-cni.component";

@Component({
    components: {
        HeaderCniComponent
    }
})
export default class HeaderComponent extends Vue
{
    protected MENU: any = window["MAIN_MENU"][0];
    protected isMenuOpen: boolean = false;

    protected showMenu: boolean = false;

    protected isExtensionOpen: string = "extension";
    protected toggleMobileSearchIcon: string = "mdi-magnify";
    protected headerHeight: number = 56;

    protected busca: string = "";
    protected currentMenuActive: string = "";

    constructor()
    {
        super();
    }

    mounted()
    {

        this.onOpenMobileSearch();

        setTimeout(() => {
            this.showMenu = true;
        }, 1000);


    }


    protected setCurrentRouteOnMenu( menuItem )
    {
        if ( menuItem.active ) {
            this.currentMenuActive = menuItem.link;
        }

    }

    protected onBeforeRouteChange( event, link ):void
    {
        event.preventDefault();

        if (
            link.indexOf( "http://" ) === -1 &&
            link.indexOf( "https://" ) === -1
        ) {
            setTimeout(()=>
            {
                window.location.href = link;
            }, 300);
        }
        else
        {
            window.open(link);
        }
    }

    protected onSearch():void
    {
        window.location.href = `/busca?q=${this.busca}`;
    }

    protected onOpenMobileSearch():void
    {
        if (
            this.isExtensionOpen === "" &&
            window.innerWidth < 960
        ) {
            this.isExtensionOpen = "extension";
            this.toggleMobileSearchIcon = "mdi-close";
        }
        else if (
            this.isExtensionOpen === "extension" &&
            window.innerWidth < 960
        ) {
            this.isExtensionOpen = "";
            this.toggleMobileSearchIcon = "mdi-magnify";
        }
        else
        {
            this.isExtensionOpen = "extension";
            this.headerHeight = 120;
        }
    }
}
