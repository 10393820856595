import Vue from "vue";
import {Component,Prop} from "vue-property-decorator";


@Component
export default class MidiasSociaisComponent extends Vue
{

    @Prop({ type: String, default: "" })
    protected facebookText!: string;

    @Prop({ type: String, default: "" })
    protected twitterText!: string;

    protected animating: string = "";

    constructor()
    {
        super();
    }

    protected visibilityChanged (isVisible: boolean, entry: string):void
    {
        if( isVisible ) {
            this.animating = "animating"
        }
    }
}
