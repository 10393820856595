import Vue from 'vue';
import { Component, Prop, Watch } from "vue-property-decorator";
import store from "@/store";

import { BannersModel } from "./slidershow.component.model";
import { BannersType } from "./slidershow.component.type";
import { QueryResult } from "vue-apollo/types/vue-apollo";

@Component
export default class SlidershowComponent extends Vue
{
    @Prop({ type: String, default: "0" })
    protected id!: string;

    @Prop({ type: Number, default: 6000 })
    protected intervalo!: number;

    @Prop({ type: Boolean, default: false })
    protected navegacaoSetas!: boolean;

    @Prop({ type: Boolean, default: false })
    protected navegacaoControles!: boolean;

    @Prop({ type: Number, default: 16/9 })
    protected aspectRatio!: Number;

    protected animating: string = "";

    protected banners: BannersType = {
        hasNext: false,
        hasPrev: false,
        objects: []
    };

    protected swiperOptionsProccessed: boolean = false;

    protected swiperOption: any = {
        slidesPerView: 1,
        loop: false,
        autoHeight: true
    };

    protected autoplay: any = {
        autoplay: {
            delay: this.intervalo,
        }
    };

    protected pagination: any = {
        pagination: {
            el: '.cms-carousel__pagination',
            clickable: true
        }
    };

    protected navigation: any = {
        navigation: {
            nextEl: '.cms-carousel__next',
            prevEl: '.cms-carousel__prev'
        }
    };

    get baseUrl():string
    {
        return store.state.server;
    }

    constructor()
    {
        super();
        this.queryBanners();
    }

    protected mounted()
    {
        this.mergeOptions();

        setTimeout(()=>{

            this.animating = "animating";

        },300)
    }

    protected mergeOptions():void
    {
        if ( this.navegacaoControles )
        {
            this.swiperOption = Object.assign({}, this.swiperOption, this.pagination);
        }

        if ( this.navegacaoSetas )
        {
            this.swiperOption = Object.assign({}, this.swiperOption, this.navigation);
        }

        this.swiperOptionsProccessed = true;
    }

    @Watch("id")
    protected queryBanners():void
    {
        this.$apollo.query({
            query: BannersModel.filter.query,
            variables: {
                categoria: "agrupador",
                id: this.id
            }
        })
        .then(( data: QueryResult<any> )=>
        {
            this.banners = data.data.banners;
        })
        .catch(( error: any )=>
        {
            console.error("SlidershowComponent Query Error", error);
        });
    }

    @Watch("banners")
    protected onBannersChange( value: any )
    {
        for ( let slide of value.objects )
        {
            if ( slide.image.indexOf(this.baseUrl) === -1 )
            {
                slide.image = `${this.baseUrl}${slide.image}`;
            }
        }
    }
}
