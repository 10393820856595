import { Vue, Component } from 'vue-property-decorator';

@Component
export default class AppComponent extends Vue
{
    constructor()
    {
        super();
    }
}
