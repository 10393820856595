import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import GaleriaSlideComponent from "../galeria-slide/galeria-slide.component.vue"

@Component({
    components: {
        GaleriaSlideComponent
    }
})
export default class GaleriaComponent extends Vue
{
    @Prop({ type: Boolean, default: false })
    protected rotativo!: boolean;

    @Prop({ type: Number, default: 6000 })
    protected intervalo!: number;

    @Prop({ type: String, default: "slide" })
    protected animacao!: "slide" | "fade";

    @Prop({ type: Boolean, default: true })
    protected navegacaoSetas!: boolean;

    @Prop({ type: Boolean, default: false })
    protected navegacaoControles!: boolean;

    @Prop({ type: Number, default: 16/9 })
    protected aspectRatio!: Number;

    @Prop({ type: String, default: "tamanho-imagem" })
    protected tamanho!: "tamanho-imagem" | "full-screen" | "custom";

    @Prop({ type: Number, default: 500 })
    protected larguraImagem!: number;

    @Prop({ type: String, default: "center" })
    protected alinhamento!: "left" | "center" | "right";

    protected swiperOptionsProccessed: boolean = false;

    protected swiperOption: any = {
        slidesPerView: 1,
        loop: false,
        effect: this.animacao
    };

    protected autoplay: any = {
        autoplay: {
            delay: this.intervalo,
        }
    };

    protected pagination: any = {
        pagination: {
            el: ".cms-galeria__pagination",
            clickable: true
        }
    };

    protected navigation: any = {
        navigation: {
            nextEl: ".cms-galeria__next",
            prevEl: ".cms-galeria__prev"
        }
    };

    constructor()
    {
        super();
    }

    protected mounted()
    {
        this.mergeOptions();
    }

    protected mudaTamanhoImagem():string
    {
        switch( this.tamanho )
        {
            case "full-screen":
                return "100%";
            default:
                return `${this.larguraImagem}px`;
        }
    }

    protected mergeOptions():void
    {
        if ( this.navegacaoControles )
        {
            this.swiperOption = Object.assign({}, this.swiperOption, this.pagination);
        }

        if ( this.navegacaoSetas )
        {
            this.swiperOption = Object.assign({}, this.swiperOption, this.navigation);
        }

        if ( this.rotativo )
        {
            this.swiperOption = Object.assign({}, this.swiperOption, this.autoplay);
        }

        this.swiperOptionsProccessed = true;
    }
}
