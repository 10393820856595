import Vue from "vue";
import { Component, Prop } from 'vue-property-decorator';

@Component

export default class ListaLinksComponent extends Vue
{
    @Prop({ default: "" })
    private links!: any;

    constructor()
    {
        super();
        this.Init();
    }

    private Init():void
    {

    }

}
