import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class CollapseContentComponent extends Vue
{
    @Prop({ type: String, default: "" })
    private title!: string;

    constructor()
    {
        super();
    }
}