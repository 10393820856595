import Vue from 'vue'
import { Component } from "vue-property-decorator";
import { ContatoModel } from "./contato.component.model";

@Component({
    $_veeValidate: {
        validator: "new"
    }
})
export default class ContatoComponent extends Vue
{
    protected snackbar: boolean = false;
    protected sendStatus: string = "info";

    protected nome: string = "";
    protected email: string = "";
    protected mensagem: string = "";
    protected instituicao: any = [
        {
            value: "CNI",
            text: "CNI"
        },
        {
            value: "SESI",
            text: "SESI"
        },
        {
            value: "SENAI",
            text: "SENAI"
        },
        {
            value: "IEL",
            text: "IEL"
        }
    ];
    protected Instituicao: String = "";

    constructor()
    {
        super();
    }

    protected enviar():void
    {
        this.$validator.validateAll()
        .then(( result: boolean )=>
        {
            if ( result )
            {
                this.sendStatus = "";

                this.$apollo.mutate({
                    mutation: ContatoModel.register,
                    variables: {
                        nome: this.nome,
                        email: this.email,
                        mensagem: this.mensagem,
                        instituicao: this.Instituicao
                    }
                })
                .then(( data: any )=>
                {
                    this.sendStatus = "success";
                    this.snackbar = true;
                    this.zerarCampos();
                })
                .catch(( error: any )=>
                {
                    this.sendStatus = "error";
                    this.snackbar = true;
                });

                return;
            }

            this.sendStatus = "error";
            return;
        })
        .catch(( error: any )=>
        {
            this.sendStatus = "error";
            this.snackbar = true;
        });
    }

    protected zerarCampos():void{

        this.$nextTick(() => {
            this.$validator.reset();
            this.nome = "";
            this.email = "";
            this.mensagem = "";
            this.Instituicao = "";
        })

    }


}
