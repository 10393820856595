import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class ImagemParallaxComponent extends Vue
{
    @Prop({ type: String, default: "https://picsum.photos/1920/1080?random" })
    protected imagem!: string;

    @Prop({ type: Number, default: 500 })
    protected altura!: number;

    constructor()
    {
        super();
    }
}