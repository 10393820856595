import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/src/stylus/app.styl'
import pt from 'vuetify/src/locale/pt'

import {
    VContainer,
    VLayout,
    VFlex,
    VTabs,
    VTab,
    VNavigationDrawer,
    VList,
    VTabItem
} from 'vuetify/lib';

Vue.use(Vuetify, {
    components: {
        VContainer,
        VLayout,
        VFlex,
        VTabs,
        VTab,
        VNavigationDrawer,
        VList,
        VTabItem
    },
    theme: {
        primary: '#006BB7',
        secondary: '#006BB7',
        accent: '#006BB7',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
    },
    customProperties: true,
    iconfont: 'mdi',
    lang: {
        locales: {pt},
        current: 'pt'
    },
});
