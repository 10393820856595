import Vue from "vue";
import {Component, Prop, Watch} from "vue-property-decorator";
import fetch from "unfetch";
import store from '@/store';

@Component ({
    filters: {
        getDate(value: string) {
            let valueA:any
            value = value || '';
            valueA = value.replace('T','-');
            value = valueA.split('-');

            let year = value[0];
            let month = value[1];
            let day = value[2];

            let datafinal = day + "/" + month + "/" + year ;
            return datafinal;
        },
        noAccents(value: string) {
            return value.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        },
        lowerCase(value: string) {
            return value.toLowerCase().trim()
        },
        spaceDecode(value: string) {
            return decodeURIComponent(value).replace("%20", " ")
        }

    }
})

export default class ListaComponent extends Vue
{

    protected urlAgencia: string = "/conteudo_agencia/api/noticias";

    @Prop({ type: String, default: "" })
    protected tipo!: string;

    @Prop({ type: Number, default: 9 })
    protected limiteItens!: number;

    @Prop({ type: Boolean, default: true })
    protected comFiltro!: boolean;

    protected loading: boolean = false;
    protected noticias: any = [];
    protected ultimoItem: boolean = false;
    protected paginacao: number = 0;
    protected urlAgenciaFinal: any = "";
    protected parametrosBuscado:string = ( window.location.href.search('/busca') > -1 && window.location.search ) ? window.location.search.split("=")[1] : "";

    protected tipoParametro = () => {
        if (this.tipo === 'posicionamentos') {
            return "tipos=posicionamentos"
        } else {
            return "segmentacoes=release"
        }
    };

    protected buscaParametro = ()=> {

        if ( window.location.href.search('/busca') > -1 ) {

            return `q=%27${this.parametrosBuscado}%27`

        } else {
            return ""
        }
    };

    constructor()
    {
        super();

    }

    protected getLista():void {

        this.loading = true;
        this.paginacao ++;
        this.urlAgenciaFinal = `${this.urlAgencia}?limit=${this.limiteItens}&${this.tipoParametro()}&${this.buscaParametro()}&page=${this.paginacao}`;

        fetch(this.urlAgenciaFinal)
            .then(response => response.json() )
            .then(( response: any )=>
            {
                this.noticias = this.noticias.concat(response.results);
                this.ultimoItem = response.next;
                this.loading = false;

            },
            ( response: any )=>
            {
                console.error( "ListaComponent Error Query", response );
            });
    }

    mounted()
    {
        this.getLista();
    }

    get getFiltro(){
        return store.state.filtro
    }



    @Watch('getFiltro')
    protected filtrar(filtro: any):void {

        if ( filtro ) {

            this.noticias = [];
            this.loading = true;
            this.paginacao = 1;
            this.urlAgenciaFinal = `${this.urlAgencia}?limit=${this.limiteItens}&${this.tipoParametro()}&${this.buscaParametro()}&page=${this.paginacao}`;

            if ( filtro['dataInicial'] && filtro['dataFinal'] ) {
                this.urlAgenciaFinal = `${this.urlAgenciaFinal}&data_inicial=${filtro['dataInicial']}&data_final=${filtro['dataFinal']}`;
            }
            if ( filtro['editoria'] ) {
                this.urlAgenciaFinal = `${this.urlAgenciaFinal}&editorias=${filtro['editoria']}`;
            }

            fetch(this.urlAgenciaFinal)
                .then(response => response.json())
                .then(( response: any )=>
                    {
                        this.loading = false;
                        this.noticias = response.results;
                        this.ultimoItem = response.next;

                    },
                    ( response: any )=>
                    {
                        console.error( "AgenciaNoticiasComponent Error Query", response );
                    });

        }
    }

    protected carregarMais():void {
        this.getLista();
    }

}
