import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class VideoComponent extends Vue
{
    @Prop({ type: String, default: "" })
    protected video!: string;

    @Prop({ type: String, default: "" })
    protected imagem!: string;

    @Prop({ type: String, default: "" })
    protected legenda!: string;

    @Prop({ type: Number, default: 16/9 })
    protected aspectRatio!: number;

    @Prop({ type: Number, default: 0 })
    protected tamanho!: 0 | 1;

    @Prop({ type: Number, default: 500 })
    protected larguraImagem!: number;

    @Prop({ type: String, default: "center" })
    protected alinhamento!: "left" | "center" | "right";

    @Prop({ type: Boolean, default: true })
    protected hasModal!: boolean;

    protected viewSize: number = 0;
    protected modal: boolean = false;
    protected playVideoSemModal: boolean = false;
    protected larguraImagemFinal: string = "";

    constructor()
    {
        super();
    }

    mounted()
    {
        this.watchViewport();
    }

    protected mudaStatusModal():void
    {
        this.modal = !this.modal;
    }

    protected watchViewport():void
    {
        window.addEventListener("resize", ( event: UIEvent )=>
        {
            this.viewSize = window.innerWidth;
        });
    }

    protected playVideo()
    {
        this.playVideoSemModal = true;
        this.video = `${this.video}?autoplay=1`;
    }

    protected retornaLarguraImagem()
    {
        if (this.tamanho === 0)
        {
            this.larguraImagemFinal = '100%';
        } else {
            this.larguraImagemFinal = `${this.larguraImagem}px`;
        }
        return this.larguraImagemFinal;
    }
}
