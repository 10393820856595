import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class ImagemComponent extends Vue
{
    @Prop({ type: String, default: "" })
    protected imagem!: string;

    @Prop({ type: String, default: "" })
    protected legenda!: string;

    @Prop({ type: Number, default: "" })
    protected larguraImagem!: number;

    @Prop({ type: Number, default: 0 })
    protected larguraCustomizada!: number;

    @Prop({ type: Number, default: 0 })
    protected tamanho!: number;

    @Prop({ type: String, default: "center" })
    protected alinhamento!: "left" | "center" | "right";

    @Prop({ type: Boolean, default: true })
    protected hasModal!: boolean;

    protected modal: boolean = false;
    protected larguraImagemFinal: string = "";

    constructor()
    {
        super();
    }

    mounted()
    {

    }

    protected mudaStatusModal(){
        this.modal = !this.modal;
    }

    protected retornaLarguraImagem()
    {
        // Fullscreen: 0
        // Custom: 1
        // TamanhoDaImagem: 2

        if ( this.tamanho === 0 )
        {
            this.larguraImagemFinal = '100%';
        }

        if ( this.tamanho === 1 )
        {
            this.larguraImagemFinal = `${this.larguraCustomizada}px`;
        }

        if ( this.tamanho === 2 )
        {
            this.larguraImagemFinal = `${this.larguraImagem}px`;
        }

        return this.larguraImagemFinal;
    }
}