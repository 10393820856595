import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        assets: "",
        server: "",
        graphql: "",
        filtro: {}
    },
    mutations: {
        setAssets( state, assets: string )
        {
            state.assets = assets;
        },
        setServer( state, server: string )
        {
            state.server = server;
        },
        setGrapql( state, graphql: string )
        {
            state.graphql = graphql;
        },
        updateFiltro(state, filtro: object)
        {
            state.filtro = filtro;
        }
    },
    actions: {
        setEnvironment( context ):void
        {
            let localServer: string = "http://192.168.33.10:8000";

            if ( window.location.port === "8080" )
            {
                context.commit("setAssets", window.location.origin);
                context.commit("setServer", localServer);
                context.commit("setGrapql", `${localServer}/graphql/`);
            }
            else
            {
                context.commit("setAssets", `${window.location.origin}/static`);
                context.commit("setServer", window.location.origin);
                context.commit("setGrapql", `${window.location.origin}/graphql/`);
            }
        }
    },
});
