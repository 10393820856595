import { Vue, Component } from "vue-property-decorator";

@Component
export default class HeaderCniComponent extends Vue
{
    protected alinhamento: boolean = false;

    constructor()
    {
        super();
    }

    mounted()
    {
        this.alinhamento = window.innerWidth < 960;
    }
}
