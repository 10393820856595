import { render, staticRenderFns } from "./link.component.vue?vue&type=template&id=6880e9f7&"
import script from "./link.component.ts?vue&type=script&lang=ts&"
export * from "./link.component.ts?vue&type=script&lang=ts&"
import style0 from "./link.component.sass?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "link.component.vue"
export default component.exports