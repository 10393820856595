import Vue from "vue";
import store from "./../../store";
import {Component, Prop, Watch} from "vue-property-decorator";

@Component
export default class ListaFiltroComponent extends Vue {

    @Prop({ type: String, default: "" })
    protected tipo!: string;

    protected Editoria:string = "";

    protected editorias:Array<any> = [
        {
            "id": 1,
            "nome": "Competitividade"
        },
        {
            "id": 2,
            "nome": "Desenvolvimento associativo"
        },
        {
            "id": 12,
            "nome": "Economia"
        },
        {
            "id": 3,
            "nome": "Educação"
        },
        {
            "id": 4,
            "nome": "Infraestrutura"
        },
        {
            "id": 5,
            "nome": "Inovação e tecnologia"
        },
        {
            "id": 6,
            "nome": "Institucional"
        },
        {
            "id": 14,
            "nome": "Internacional"
        },
        {
            "id": 7,
            "nome": "Leis e normas"
        },
        {
            "id": 9,
            "nome": "Pequenas empresas"
        },
        {
            "id": 10,
            "nome": "Política industrial"
        },
        {
            "id": 13,
            "nome": "Qualidade de vida"
        },
        {
            "id": 8,
            "nome": "Sustentabilidade"
        },
        {
            "id": 11,
            "nome": "Trabalho"
        }
    ];

    protected dateInicial:string = "";
    protected dateInicialFormated:string = "";
    protected menuInicial: any = false;
    protected modalInicial: any = false;

    protected dateFinal:string = "";
    protected dateFinalFormated:string = "";
    protected menuFinal: any = false;
    protected modalFinal: any = false;

    constructor() {
        super();
    }

    mounted() {
    }

    protected filtrar (): void {

        let dataInicial: string = "";
        let dataFinal: string = "";

        if ( this.dateInicial && this.dateFinal){

            const anoInicial = this.dateInicial.split("-")[0] || "";
            const mesInicial = this.dateInicial.split("-")[1] || "";
            dataInicial = `${anoInicial}-${mesInicial}-01`;

            const anoFinal = this.dateFinal.split("-")[0] || "";
            const mesFinal = this.dateFinal.split("-")[1] || "";
            const diaFinal = this.daysInMonth(mesFinal, anoFinal);
            dataFinal = `${anoFinal}-${mesFinal}-${diaFinal}`;

        }

        let filtro: Object = {
            editoria: this.Editoria,
            dataInicial: dataInicial,
            dataFinal: dataFinal
        };

        store.commit('updateFiltro', filtro )
    }

    @Watch('dateInicial')
    protected formatarInicial(){
        this.dateInicialFormated = this.formatDate(this.dateInicial);
    }

    @Watch('dateFinal')
    protected formatarFinal(){
        this.dateFinalFormated = this.formatDate(this.dateFinal);
    }

    protected formatDate(date: any){

        const [ano, mes] = date.split('-');
        const mesName = new Date(ano, (Number(mes)-1), 1).toLocaleString("pt-br", {month: "long"})

        return `${mesName} de ${ano}`;

    }

    protected daysInMonth (month:any, year:any) {
        return new Date(year, month, 0).getDate();
    }


}
