import Vue from 'vue'
import { Component } from "vue-property-decorator";
import { NewsletterModel } from "./newsletter.component.model";

@Component({
    $_veeValidate: {
        validator: "new"
    }
})
export default class NewsletterComponent extends Vue
{
    protected nome: string = "";
    protected email: string = "";

    protected sendStatus: string = "info";
    protected snackbar: boolean = false;
    protected statusMessage: string = "Houve um problema ao realizar o envio. Tente novamente.";

    constructor()
    {
        super();

    }

    protected enviar():void
    {
        this.$validator.validateAll()
        .then(( result )=>
        {

            if ( result )
            {
                this.sendStatus = "";

                this.$apollo.mutate({
                    mutation: NewsletterModel.register,
                    variables: {
                        nome: this.nome,
                        email: this.email
                    }
                })
                .then(( response: any )=>
                {

                    if( response.data['createNewsletter'].status ){//success
                        this.sendStatus = "success";
                        this.snackbar = true;
                        this.$nextTick(() => {
                            this.$validator.reset();
                            this.nome = this.email = '';
                        })

                    } else {//ja cadastrado
                        this.sendStatus = "error";
                        this.snackbar = true;
                        this.statusMessage = response.data['createNewsletter'].message || this.statusMessage;
                    }

                })
                .catch(( error: any )=>
                {
                    this.sendStatus = "error";
                    this.snackbar = true;
                    console.error( error );
                });
                return;
            }

            this.sendStatus = "error";
            return;
        })
        .catch(( error: any )=>
        {
            this.sendStatus = "error";
            this.snackbar = true;
        })
    }

}
