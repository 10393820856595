import Vue from "vue";
import { Component } from "vue-property-decorator";
import ImagemComponent from "../imagem/imagem.component.vue"

@Component({
    components: {
        ImagemComponent
    }
})
export default class GaleriaSlideComponent extends Vue
{
    constructor()
    {
        super();
    }
}
