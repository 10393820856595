import Vue from "vue";
import {Component,Prop} from "vue-property-decorator";

@Component
export default class AtendimentoAImprensaComponent extends Vue
{

    @Prop({ type: String, default: "" })
    private tel!: string;

    @Prop({ type: String, default: "" })
    private email!: string;

    constructor()
    {
        super();
    }

}
