var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
        'cms-imagem': true,
        'cms-imagem--center': _vm.alinhamento === 'center',
        'cms-imagem--left': _vm.alinhamento === 'left',
        'cms-imagem--right': _vm.alinhamento === 'right'
     },style:({ 'width': _vm.retornaLarguraImagem(), 'height': 'auto' })},[(_vm.imagem.length)?_c('v-img',{class:{
                'cms-imagem__src': true,
                'cms-imagem__src--pointer': _vm.hasModal
           },attrs:{"src":_vm.imagem,"lazy-src":_vm.imagem,"width":_vm.retornaLarguraImagem(),"height":"auto"}},[_c('v-layout',{attrs:{"slot":"placeholder","fill-height":"","align-center":"","justify-center":"","ma-0":""},slot:"placeholder"},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1),(_vm.hasModal)?_c('v-layout',{staticClass:"cms-imagem__overlay",attrs:{"fill-height":"","align-start":"","justify-end":"","ma-0":""},on:{"click":_vm.mudaStatusModal}},[_c('v-btn',{staticClass:"cms-imagem__overlay__action",attrs:{"color":"#ffffff","outline":"","small":"","fab":""}},[_c('v-icon',[_vm._v("\n                    mdi-arrow-expand-all\n                ")])],1)],1):_vm._e(),(_vm.legenda)?_c('v-container',{attrs:{"fluid":"","align-end":"","justify-start":"","fill-height":""}},[_c('v-layout',{attrs:{"fill-height":"","align-end":""}},[_c('span',{staticClass:"body-2 white--text"},[_vm._v("\n                    "+_vm._s(_vm.legenda)+"\n                ")])])],1):_vm._e(),(!_vm.legenda)?_c('v-container',{staticClass:"cms-imagem__container",attrs:{"align-center":"","justify-center":"","fill-height":""}},[_c('div',[_vm._t("default")],2)]):_vm._e()],1):_vm._e(),(_vm.hasModal)?_c('v-dialog',{attrs:{"scrollable":"","full-width":""},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-img',{staticClass:"cms-imagem__dialog",attrs:{"src":_vm.imagem,"lazy-src":_vm.imagem,"contain":""}},[_c('v-layout',{attrs:{"slot":"placeholder","fill-height":"","align-center":"","justify-center":"","ma-0":""},slot:"placeholder"},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }