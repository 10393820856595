import Vue from "vue";
import { Component, Prop } from 'vue-property-decorator';
import TituloComponent from "../titulo/titulo.component.vue";

@Component({
    components: {
        TituloComponent
    }
})

export default class LinkComponent extends Vue
{
    @Prop({ type: String, default: "" })
    private texto!: string;

    @Prop({ type: String, default: "" })
    private url!: string;

    @Prop({ type: String, default: "" })
    private title!: string;

    @Prop({ type: String, default: "_self" })
    private target!: "_self" | "_blank" | "_parent" | "_top";

    @Prop({ type: Boolean, default: false })
    private downloadFile!: boolean;

    constructor()
    {
        super();
        this.Init();
    }

    private Init():void
    {
        if ( this.url.length && !this.dominioLocal() && !this.possuiProtocolo() )
        {
            this.insereHttp();
        }
    }

    private possuiProtocolo():boolean
    {
        let possuiHttp: number = this.url.indexOf("http://");
        let possuiHttps: number = this.url.indexOf("https://");

        return possuiHttp === 0 || possuiHttps === 0;
    }

    private dominioLocal():boolean
    {
        return this.url.indexOf("/") === 0;
    }

    private insereHttp():void
    {
        this.url = `http://${this.url}`;
    }
}
